import Vue from 'vue'

export default {
  SET_CANDIDATES (state, data) {
    Vue.set(state.candidates, state.currentPage, data)
  },
  SET_PAGES (state, pages) {
    state.pages = pages
  },
  SET_CURRENT_PAGE (state, page) {
    state.currentPage = page
  },
  SET_FILTERS (state, filters) {
    state.filters = filters
  },
  SET_SUMMARY (state, summaryData) {
    state.summaryData = summaryData
  },
  SET_FILTEROPTIONS (state, filterOptions) {
    state.filterOptions = filterOptions
  },
  SET_REPORTLINK (state, teamReportLink) {
    state.teamReportLink = teamReportLink
  },
  SET_SORTING (state, sorting) {
    state.sorting = sorting
  },
  SET_ISSUES (state, data) {
    state.issues = data
  },
  SET_LOW_SCORE_COMMENTS_USER (state, data) {
    state.lowScoreComments = data
  },
  EMPTY (state) {
    Object.assign(state, {
      issues: {},
      lowScoreComments: {},
      candidates: {},
      currentPage: 0,
      pages: null,
      sorting: {
        variable: 'user_id',
        invert: false
      },
      filters: {
        endurance: null,
        variation: null,
        capacity_level: null,
        state: {
          RED: false,
          YELLOW: false,
          GREEN: false
        },
        awareness: {
          RED: false,
          YELLOW: false,
          GREEN: false
        },
        gender: {
          Male: false,
          Female: false,
          Other: false
        },
        strength: {
          working_memory: false,
          simple_attention: false,
          strategic_thinking: false,
          short_term_memory: false,
          impulse_control: false,
          creativity_cognitive_flexibility: false,
          conceptualization: false,
          attention_control: false
        }
      }
    })
  }
}
