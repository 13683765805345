
export default {
  setCurrentTeam ({ commit, rootState }, campaignId) {
    const { teams, currentPage } = rootState.TEAMS

    const currentTeam = teams[currentPage].find(team => team.id === campaignId)

    commit('SET_CURRENT_TEAM', currentTeam)
  }
}
