const Module = () => import('./Module.vue')
const Report = () => import('./views/Report.vue')

const moduleRoute = {
  path: '/teams',
  component: Module,
  meta: {
    requiresAuth: true
  },
  children: [
    {
      name: 'TeamReport',
      path: ':campaignId/report',
      component: Report,
      props: route => ({
        altId: route.params.altId,
        campaignId: route.params.campaignId,
        pdf: String(route.query.pdf).toLowerCase() === 'true',
        organisationId: route.query.organisationId,
        otp: route.query.otp,
        anonymized: !route.query.anonymized || String(route.query.anonymized).toLowerCase() === 'true'
      })
    }
  ]
}

export default router => {
  router.addRoute(moduleRoute)
}
