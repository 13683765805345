
export default {
  namespaced: true,
  state: {
    tests: [],
    questionnaire: {},
    numOfTestsOptions: []
  },
  getters: {
    getTestConfigurations (state) {
      return state.tests
    }
  },
  actions: {
    async getTestConfigurations ({ commit }) {
      const configurations = await this.$api.getTestConfigs()
      commit('SET_CONFIGS', configurations)
    }
  },
  mutations: {
    SET_CONFIGS (state, data) {
      state.tests = data
    }
  }
}
