import store from '@/store'

const Module = () => import('./Module.vue')
const Questionnaire = () => import('./views/Questionnaire.vue')
const StartPage = () => import('./views/StartPage.vue')
const NotFound = () => import('./views/NotFound.vue')
const Finished = () => import('./views/Finished.vue')

const validInvite = (to, from, next) => {
  if (store.getters['QUESTIONNAIRE/validInvite']) {
    next()
  } else {
    next('/questionnaire/notfound')
  }
}

const getQuestionnaire = async (to, from, next) => {
  const validId = await store.dispatch('QUESTIONNAIRE/getQuestionnaire', to.params.id)
  if (validId) {
    next()
  } else {
    next('/questionnaire/notfound')
  }
}

const moduleRoute = {
  path: '/questionnaire',
  name: 'Questionnaire',
  component: Module,
  children: [
    {
      path: 'invite/:id',
      component: StartPage,
      beforeEnter: getQuestionnaire
    },
    {
      path: 'notfound',
      component: NotFound
    },

    {
      path: 'finished',
      component: Finished,
      beforeEnter: validInvite
    },
    {
      path: ':type',
      component: Questionnaire,
      beforeEnter: validInvite
    }

  ]
}

export default router => {
  router.addRoute(moduleRoute)
}
