
export default {
  getCampaignId: state => {
    return state.campaignId
  },
  getTeamRatingUsers: state => {
    return state.teamRatingUsers
  },
  getRatingUser: state => {
    return state.ratingUser
  },
  getSelectedLang: state => {
    return state.selectedLang
  },
  getQuestions: state => {
    if (state.selectedLang === 'EN' && state.traits && state.cognitive) {
      return state.questions.cognitiveEn.concat(state.questions.traitsEn)
    } else if (state.selectedLang === 'EN' && !state.traits && state.cognitive) {
      return state.questions.cognitiveEn
    } else if (state.selectedLang === 'EN' && state.traits && !state.cognitive) {
      return state.questions.traitsEn
    // eslint-disable-next-line brace-style
    }
    // questions in swedish
    else if (state.selectedLang === 'SV' && state.traits && state.cognitive) {
      return state.questions.cognitiveSv.concat(state.questions.traitsSv)
    } else if (state.selectedLang === 'SV' && !state.traits && state.cognitive) {
      return state.questions.cognitiveSv
    } else if (state.selectedLang === 'SV' && state.traits && !state.cognitive) {
      return state.questions.traitsSv
    } else {
      console.error('something went wrong with getting questions for data')
    }
  },
  getQuestionsForData: state => {
    if (state.traits && state.cognitive) {
      return state.questionsForData.cognitive.concat(state.questionsForData.traits)
    } else if (state.traits && !state.cognitive) {
      return state.questionsForData.traits
    } else if (!state.traits && state.cognitive) {
      return state.questionsForData.cognitive
    } else {
      console.error('something went wrong with getting questions for data')
    }
  },
  getOptions: state => {
    return {
      cognitive: state.cognitive,
      traits: state.traits
    }
  }
}
