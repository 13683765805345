import { Chart, registerables, LineController, Interaction, PointElement } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'

Chart.register(ChartDataLabels, ...registerables)
Chart.defaults.plugins.datalabels.display = false
Chart.defaults.font.family = 'Poppins'

const indicatorOptions = {
  radius: 0, borderWidth: 1, borderColor: 'black', backgroundColor: 'black'
}

const getLabelAndValue = LineController.prototype.getLabelAndValue
LineController.prototype.getLabelAndValue = function (index) {
  if (index === -1) {
    return {
      value: 'A mayor increase (2 points or more) may suggest a slow starter, someone who wants to understand the task berfore accelerating or someone who performs best under pressure.'
    }
  }
  return getLabelAndValue
}

Interaction.modes.interpolate = function (chart, e, option) {
  const items = []
  const metas = chart.getSortedVisibleDatasetMetas()
  for (let i = 0; i < metas.length; i++) {
    const meta = metas[i]
    if (meta.dataset) {
      const [{ x: x1, y: y1 }, { x: x2, y: y2 }] = meta.dataset._points
      const element = new PointElement({ ...{ x: (x1 + x2) / 2, y: (y1 + y2) / 2 }, options: { ...indicatorOptions } })
      meta._pt = element
      items.push({ element, index: -1, datasetIndex: meta.index })
    }
    return items
  }
}

// Plugin to draw the indicators
Chart.register({
  id: 'indicators',
  afterDraw (chart) {
    const metas = chart.getSortedVisibleDatasetMetas()
    for (let i = 0; i < metas.length; i++) {
      const meta = metas[i]
      if (meta._pt) {
        meta._pt.draw(chart.ctx)
      }
    }
  },
  afterEvent (chart, args) {
    if (args.event.type === 'mouseout') {
      const metas = chart.getSortedVisibleDatasetMetas()
      for (let i = 0; i < metas.length; i++) {
        metas[i]._pt = null
      }
      args.changed = true
    }
  }
})
