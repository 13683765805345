const Module = () => import('./Module.vue')
const Login = () => import('./views/Login.vue')
const ForgotPassword = () => import('./views/ForgotPassword.vue')
const ChangePassword = () => import('./views/ChangePassword.vue')
const ResetPassword = () => import('./views/ResetPassword.vue')

const moduleRoute = {
  path: '/login',
  component: Module,
  children: [
    {
      path: '',
      component: Login,
      beforeEnter: (to, from, next) => {
        const user = localStorage.getItem('user')
        if (user) {
          next({ name: 'Home' })
        } else {
          next()
        }
      }
    },
    {
      path: 'forgot',
      component: ForgotPassword
    },
    {
      path: 'change',
      component: ChangePassword,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'reset/:id',
      component: ResetPassword
    }
  ]
}

export default router => {
  router.addRoute(moduleRoute)
}
