
import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import '@/styles/app.css'
import http from '@/api/http'
import httpAdmin from '@/api/httpAdmin'
import api from '@/api'
import './plugins/Charts'
import '@/plugins/Chargebee'
import '@/plugins/Azure'
import '@/plugins/Crisp'
import VueClipboard from 'vue-clipboard2'
import VueCompositionAPI from '@vue/composition-api'
import VueTippy, { TippyComponent } from 'vue-tippy'

// IMPORT OUR MODULES
import loginModule from '@/modules/Login'
import signupModule from '@/modules/SignUp'
import createCampaignModule from '@/modules/CreateCampaign'
import dashboardModule from '@/modules/Dashboard'
import DemoVideosModule from '@/modules/DemoVideos'
import overviewModule from '@/modules/Overview'
import analyticsModule from '@/modules/Analytics'
import questionnaireModule from '@/modules/Questionnaire'
import reportModule from '@/modules/AppReport'
import scoreboardModule from '@/modules/Scoreboard'
import verifyModule from './modules/Verify'
import organisationModule from './modules/Organisation'
import teamRatingModule from './modules/team-rating'
import TeamRatingModule from './modules/TeamRating'
import personalityReport from './modules/PersonalityReport'
import requirementSpecificationModule from '@/modules/RequirementSpecification'
import ProfileModule from './modules/Profile'
import SubReportModule from '@/modules/SubReport'
import TeamBrainModule from './modules/TeamBrain'
import CreateTeamModule from './modules/CreateTeam'
import TeamAnalyticsModule from './modules/TeamAnalytics'
import CheckoutModule from '@/modules/Checkout'
import TeamReportModule from './modules/TeamReport'
import ExecutiveReportModule from './modules/ExecutiveReport'
import TeamScoreboardModule from './modules/TeamScoreboard'
import TeamReportAutoModule from './modules/TeamReportAuto'
import TeamHomeModule from './modules/TeamDashboard'

import { registerModules } from './utils/registerModules'

import adminModule from './modules/Admin'

// REGISTER MODULES
registerModules({
  login: loginModule,
  signup: signupModule,
  createCampaign: createCampaignModule,
  scoreboard: scoreboardModule,
  dashboard: dashboardModule,
  demovideos: DemoVideosModule,
  overview: overviewModule,
  analytics: analyticsModule,
  questionnaire: questionnaireModule,
  report: reportModule,
  verify: verifyModule,
  organisationModule,
  teamRating: teamRatingModule,
  TeamRating: TeamRatingModule,
  admin: adminModule,
  personalityReport,
  requirementSpecification: requirementSpecificationModule,
  Profile: ProfileModule,
  SubReport: SubReportModule,
  TeamBrain: TeamBrainModule,
  CreateTeam: CreateTeamModule,
  TeamAnalytics: TeamAnalyticsModule,
  TeamScoreboard: TeamScoreboardModule,
  TeamReport: TeamReportModule,
  TeamReportAuto: TeamReportAutoModule,
  TeamHome: TeamHomeModule,
  checkout: CheckoutModule,
  ExecutiveReport: ExecutiveReportModule
})

Vue.prototype.$API_URL = process.env.VUE_APP_API_URL
Vue.prototype.$APP_FRONTEND_URL = process.env.VUE_APP_APP_FRONTEND_URL
Vue.prototype.$http = http
Vue.prototype.$httpAdmin = httpAdmin

Vue.prototype.$api = api
store.$http = http
store.$api = api

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

Vue.use(VueCompositionAPI)

Vue.config.productionTip = false
Vue.use(VueTippy)
Vue.component('tippy', TippyComponent)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
