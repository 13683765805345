export default {

  async getResults ({ commit }, { otp, campaignId }) {
    let data
    try {
      if (otp) {
        data = await this.$api.getTeamReportData(campaignId)
      } else {
        data = await this.$api.getTeamReportData(campaignId)
      }
      if (data.message) {
        return data
      }
    } catch (err) {
      console.log(err)
      return err
    }

    const teamMembers = []
    const teamMembersSummarized = {}
    const measureScores = {}
    const teamMeasureScores = {}
    data.data.teamMembers.forEach(member => {
      teamMembers.push(member.user_id)
      teamMembersSummarized[member.user_id] = member
      measureScores[member.user_id] = []
    })
    const teamIds = ['min', 'mean', 'max'] // 'user_id' values for team data
    teamIds.forEach(id => {
      teamMeasureScores[id] = []
    })

    data.data.measureScores.forEach(score => {
      if (teamIds.includes(score.user_id)) {
        teamMeasureScores[score.user_id].push(score)
      } else {
        measureScores[score.user_id].push(score)
      }
    })

    commit('SET_TEAM_MEMBERS', teamMembers)
    commit('SET_TEAM_MEMBERS_SUMMARIZED', teamMembersSummarized)
    commit('SET_MEASURE_SCORES', measureScores)
    commit('SET_TEAM_MEASURE_SCORES', teamMeasureScores)

    return {}
  },

  async setLoadedData ({ commit }, val) {
    commit('SET_LOADED_DATA', val)
  },

  async empty ({ commit }) {
    commit('EMPTY')
  }
}
