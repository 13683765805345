export default {
  async signUp ({ commit }, creds) {
    try {
      const resData = await this.$api.register(creds)
      if (resData.error) {
        if (resData.error === 'email') return 'email_used'
      } else {
        return 'success'
      }
    } catch (error) {
      if (error.response.data.statusCode === 400) return 'email_invalid'
      if (error.response.status === 404) return 'invalid_coupon'
    }
  },

  async loginPassword ({ commit }, creds) {
    const res = await this.$api.login(creds)

    if (res.status === 401) {
      return false
    } else {
      commit('SET_USER', res.data)
      return true
    }
  },

  async updateSubscriptionStatus ({ commit }) {
    const res = await this.$api.getSubscriptionStatus()

    commit('UPDATE_SUBSCRIPTION_STATUS', res)
  }
}
