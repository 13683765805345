export default {
  async getCandidates ({ state, commit }, id) {
    const sort = {
      sortBy: state.sorting.variable,
      order: state.sorting.invert ? 'ASC' : 'DESC'
    }

    const { pages, result, summaryData, filterOptions, teamReportLink } = await this.$api.getTeamScoreboard(id, { ...state.filters, ...sort, page: state.currentPage })

    commit('SET_PAGES', pages)
    commit('SET_CANDIDATES', result)
    commit('SET_SUMMARY', summaryData)
    commit('SET_FILTEROPTIONS', filterOptions)
    commit('SET_REPORTLINK', teamReportLink)
  },
  async getCandidatesTestIssues ({ commit }, id) {
    const res = await this.$api.getScoreboardTestIssues(id)
    commit('SET_ISSUES', res)
  },
  async getLowScoreComments ({ commit }, ids) {
    const res = await this.$api.getLowScoreCommentsIssues(ids.test_session_id, ids.user_id)
    commit('SET_LOW_SCORE_COMMENTS_USER', res)
  },
  updateFilters ({ commit }, filters) {
    commit('SET_FILTERS', filters)
  },
  updateSorting ({ commit }, sorting) {
    commit('SET_SORTING', sorting)
  },
  emptyFilter ({ commit }, sorting) {
    commit('SET_FILTERS', {
      endurance: null,
      variation: null,
      capacity_level: null,
      state: {
        RED: false,
        YELLOW: false,
        GREEN: false
      },
      awareness: {
        RED: false,
        YELLOW: false,
        GREEN: false
      },
      gender: {
        Male: false,
        Female: false,
        Other: false
      },
      strength: {
        working_memory: false,
        simple_attention: false,
        strategic_thinking: false,
        short_term_memory: false,
        impulse_control: false,
        creativity_cognitive_flexibility: false,
        conceptualization: false,
        attention_control: false
      },
      requirementSpecificationId: 'NO_REQ_SPEC'
    })

    commit('SET_SORTING', {
      variable: 'user_id',
      invert: false
    })
  }
}
