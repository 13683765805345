const Module = () => import('./Module.vue')
const DemoVideos = () => import('./views/DemoVideos.vue')

const moduleRoute = {
  path: '/demo-videos',
  component: Module,
  meta: {
    requiresAuth: true
  },
  children: [
    {
      name: 'DemoVideos',
      path: '/',
      component: DemoVideos
    }
  ]
}

export default router => {
  router.addRoute(moduleRoute)
}
