import jwtDecode from 'jwt-decode'
export default {
  isLoggedAdminIn: state => {
    return state.admin.accessToken
  },
  loggedOrganizationDetails: state => {
    const decoded = jwtDecode(state.user.token)
    return decoded
  }

}
