import http from './http'
import httpAdmin from './httpAdmin'

async function login (credentials) {
  const res = await http.post('customers/login', credentials)
  return res
}

async function register (credentials) {
  const { data } = await http.post('customers/register', credentials)
  return data
}

async function changePassword (password) {
  await http.post('customers/change', { password })
}

async function forgotPassword (email) {
  await http.post('customers/forgot', { email })
}

async function resetPassword (id, password) {
  await http.post('customers/reset', { id, password })
}

async function getTestConfigs () {
  const { data } = await http.get('test-configurations?fromPortal=true')
  return data
}

async function getCampaigns (page) {
  const { data } = await http.get(`campaigns?page=${page}&size=16`)
  return data
}

async function createCampaign (body) {
  const { data: campaign } = await http.post('campaigns', body)
  return campaign
}

async function getCampaignBatteries (id) {
  const { data } = await http.get(`campaigns/${id}`)
  return data
}

async function getTestBattery (id) {
  const { data: battery } = await http.get(`test-batteries/${id}`)
  return battery
}

async function getTestCandidates (campaignId) {
  const { data: candidates } = await http.get(`reports/${campaignId}/candidates`)
  return candidates
}

async function getCampaignCandidates (campaignId, stages) {
  const { data: candidates } = await http.get(`reports/${campaignId}/campaign-candidates`, {
    params: {
      stages: stages.join()
    }
  })
  return candidates
}

async function getScoreboard (id, filtering = {}) {
  // Add all values in filtering to seperate query params
  // https://stackoverflow.com/questions/42898009/multiple-fields-with-same-key-in-query-params-axios-request

  const params = new URLSearchParams()
  for (const [key, value] of Object.entries(filtering)) {
    if (Array.isArray(value)) {
      for (const val of value) {
        params.append(key, val)
      }
    } else if (typeof value === 'object') {
      for (const val in value) {
        if (value[val]) {
          params.append(key, val)
        }
      }
    } else {
      params.append(key, value)
    }
  }

  const { data } = await http.get(`/scoreboard/${id}`, {
    params: params
  })

  return data
}
async function getQuestionnaireBattery (id) {
  const { data: battery } = await http.get(`questionnaire-batteries/${id}`)
  return battery
}

async function getQuestionnaireConfig (type, lang) {
  const { data: config } = await http.get(`questionnaire-configurations/${type}?lang=${lang}`)
  return config
}

async function getQuestionnaireCandidates (id) {
  const { data: candidates } = await http.get(`questionnaire-results/${id}/candidates`)
  return candidates
}

async function getQuestionnaireResults (userId, campaignId) {
  const { data: results } = await http.get(`questionnaire-results/${userId}?campaignId=${campaignId}`)
  return results
}

async function createQuestionnaireResults (body) {
  await http.post('questionnaire-results', body)
}

async function createUser (email) {
  const { data: user } = await http.post('create-user', { email })
  return user
}

async function getOrganisationUsers () {
  const { data } = await http.get('customers')
  return data
}

async function getPdfReport (userId, campaignId, anonymized, otp = null) {
  const res = await http.get(`/reports/${campaignId}/full/${userId}/pdf`, {
    params: {
      anonymized,
      otp
    },
    responseType: 'blob'
  })
  return res
}

async function getPdfSubReport (userId, campaignId, anonymized, type) {
  const res = await http.get(`/reports/${campaignId}/subreport/${userId}/pdf`, {
    params: {
      anonymized,
      type
    },
    responseType: 'blob'
  })
  return res
}

async function getPdfReportPersonality (id, anonymized, candidateId, campaignId) {
  const res = await http.get(`/reports/${id}/personality/pdf`, {
    params: {
      anonymized,
      candidateId,
      campaignId
    },
    responseType: 'blob'
  })

  return res
}

async function getFullReportData (userId, campaignId, otp = null) {
  const { data } = await http.get(`/reports/${campaignId}/full/${userId}`, {
    params: {
      otp
    }
  })

  return data
}

async function getChargebeePortalSession () {
  const { data } = await http.get('chargebee/portal-session')

  return data
}

async function endChargebeeTrial () {
  const { data } = await http.post('chargebee/trial/end')

  return data
}

async function getSubscriptionStatus () {
  const { data } = await http.get('subscriptions')

  return data
}

async function createFavorite (campaignId, userId) {
  const { data } = await http.post(`scoreboard/${campaignId}/favorite/${userId}`)
  return data
}

async function deleteFavorite (campaignId, userId) {
  const { data } = await http.delete(`scoreboard/${campaignId}/favorite/${userId}`)
  return data
}

async function getTeamRatingResultsForUser (campaignId, userId) {
  const { data } = await http.get(`team-rating/${campaignId}/${userId}/results`)
  return data
}

async function getTeamRatingBattery (teamRatingBatteryId, email) {
  const { data } = await http.get(`team-rating/${teamRatingBatteryId}?email=${email}`)
  return data
}

async function getAllOrganizations () {
  const { data } = await httpAdmin.get('/admin/organisations')
  return data
}

async function loginToOrganizationAsAdmin (organisationId) {
  const { data } = await httpAdmin.post('admin/login-as', { organisationId })
  return data
}

async function getFilteredOrgization (organisationName) {
  const { data } = await httpAdmin.post('admin/filterorg', { organisationName })
  return data
}

async function getRawDataReport (userId, campaignId) {
  const res = await httpAdmin.get(`/admin/raw-data-report/${campaignId}/${userId}`, {
    responseType: 'blob'
  })
  return res
}

async function createRequirementSpecification (name, requirements) {
  const { data } = await http.post('requirement-specifications', { name, requirements })

  return data
}

async function updateTeamRatingResults (body) {
  const { data } = await http.post('team-rating/results', body)
  return data
}

async function getTeamRatingResultsForGraphs (teamRatingBatteryId) {
  const { data } = await http.get(`team-rating/${teamRatingBatteryId}/results/graphData`)
  const users = await http.get(`team-rating/${teamRatingBatteryId}/users`)
  return [data, users.data]
}

async function getRequirementSpecifications () {
  const { data } = await http.get('requirement-specifications')

  return data
}

async function deleteRequirementSpecification (id) {
  const { data } = await http.delete(`requirement-specifications/${id}`)

  return data
}

async function getRequirementSpecification (id) {
  const { data } = await http.get(`requirement-specifications/${id}`)

  return data
}

async function updateRequirementSpecification (id, name, requirements) {
  const { data } = await http.put(`requirement-specifications/${id}`, { name, requirements })

  return data
}

async function getLowScoreCandidates (body) {
  const { data } = await httpAdmin.post('/admin/lowscore', body)
  return data
}

async function getAllLowScoreComments () {
  const { data } = await httpAdmin.get('/admin/lowscrorecomments')
  return data
}

async function createLowScoreComments (body) {
  const { data } = await httpAdmin.post('/admin/lowscrorecomments', body)
  return data
}

async function updateLowScoreComments (body) {
  const { data } = await httpAdmin.patch('/admin/lowscrorecomments', body)
  return data
}

async function getAllTestIssues () {
  const { data } = await httpAdmin.get('/admin/testissues')
  return data
}

async function getScoreboardTestIssues (id) {
  const { data } = await http.get(`/scoreboard/${id}/issues`)

  return data
}

async function getLowScoreCommentsIssues (testSessionId, userId) {
  const { data } = await http.get(`/scoreboard/low-score/${testSessionId}/${userId}`)

  return data
}

async function shareReport (email, reportType, userId, campaignId) {
  const { data } = await http.post(`/reports/${campaignId}/full/${userId}/share`, {
    email
  })

  return data
}

async function getSubReport (campaignId, userId, type) {
  const { data } = await http.get(`reports/${campaignId}/subreport/${userId}?type=${type}`)
  return data
}

async function generateSharableLink (reportType, userId, campaignId) {
  const { data } = await http.post(`/reports/${campaignId}/full/${userId}/share-link`)

  return data
}

async function getTeams (page) {
  const { data } = await http.get(`team?page=${page}&size=16`)

  return data
}

async function getAdminTeams (organizationId) {
  const { data: { teams } } = await httpAdmin.get(`/admin/teams/${organizationId}`)

  return teams
}

async function createTeam (body) {
  const { data: team } = await http.post('team', body)
  return team
}

async function getTeamScoreboard (id, filtering = {}) {
  // Add all values in filtering to seperate query params
  // https://stackoverflow.com/questions/42898009/multiple-fields-with-same-key-in-query-params-axios-request

  const params = new URLSearchParams()
  for (const [key, value] of Object.entries(filtering)) {
    if (Array.isArray(value)) {
      for (const val of value) {
        params.append(key, val)
      }
    } else if (typeof value === 'object') {
      for (const val in value) {
        if (value[val]) {
          params.append(key, val)
        }
      }
    } else {
      params.append(key, value)
    }
  }

  const { data } = await http.get(`team/${id}/scoreboard`, {
    params: params
  })

  return data
}

async function getTeamResults (campaignId) {
  const { data } = await http.get(`/team/${campaignId}/results`)

  return data
}

async function deleteTeamMember (id) {
  const { data } = await http.delete(`/team/members/${id}`)

  return data
}

async function updateTeamMember (id, attributes) {
  const data = await http.post(`/team/members/${id}`, attributes)

  return data
}

async function createTeamMember (body) {
  console.log({ body })
  const data = await http.post('/team/members', body)

  console.log({ data })
  return data
}

async function addReportUploaded (campaignId, reportUploaded) {
  const { data: { team } } = await httpAdmin.post(`/admin/teams/${campaignId}/add-report-uploaded`, {
    reportUploaded
  })
  return team
}

async function getFullTeamReportUploadUrl (campaignId) {
  const { data: { url } } = await httpAdmin.get(`/admin/teams/${campaignId}/report-upload-url`)
  return url
}

async function getFullTeamReportDownloadUrl (campaignId) {
  const { data: { url } } = await http.get(`/team/${campaignId}/report-download-url`)
  return url
}

async function getDocumentDownloadUrl (name) {
  const { data: { url } } = await http.get(`/team/document/${name}`)
  return url
}

async function getPdfExecSummary (userId, campaignId, anonymized) {
  const res = await http.get(`/reports/${campaignId}/summary/${userId}/pdf`, {
    params: {
      anonymized
    },
    responseType: 'blob'
  })
  return res
}

async function getTeamReportData (campaignId) {
  const res = await http.get(`/team/${campaignId}/report`)
  return res
}

export default {
  login,
  register,
  changePassword,
  forgotPassword,
  resetPassword,
  getTestConfigs,
  getCampaigns,
  createCampaign,
  getCampaignBatteries,
  getTestBattery,
  getTestCandidates,
  getCampaignCandidates,
  getQuestionnaireBattery,
  getQuestionnaireConfig,
  getQuestionnaireCandidates,
  getQuestionnaireResults,
  createQuestionnaireResults,
  createUser,
  getOrganisationUsers,
  getChargebeePortalSession,
  getPdfReport,
  getPdfSubReport,
  createFavorite,
  deleteFavorite,
  getScoreboard,
  getFullReportData,
  endChargebeeTrial,
  getSubscriptionStatus,
  getTeamRatingResultsForUser,
  getTeamRatingBattery,
  updateTeamRatingResults,
  getTeamRatingResultsForGraphs,
  getAllOrganizations,
  loginToOrganizationAsAdmin,
  getFilteredOrgization,
  getRawDataReport,
  getPdfReportPersonality,
  createRequirementSpecification,
  getRequirementSpecifications,
  deleteRequirementSpecification,
  getRequirementSpecification,
  updateRequirementSpecification,
  getLowScoreCandidates,
  getAllLowScoreComments,
  createLowScoreComments,
  updateLowScoreComments,
  getAllTestIssues,
  getScoreboardTestIssues,
  getLowScoreCommentsIssues,
  shareReport,
  getSubReport,
  generateSharableLink,
  getTeams,
  createTeam,
  getTeamScoreboard,
  getTeamResults,
  deleteTeamMember,
  updateTeamMember,
  createTeamMember,
  getAdminTeams,
  addReportUploaded,
  getFullTeamReportUploadUrl,
  getFullTeamReportDownloadUrl,
  getPdfExecSummary,
  getTeamReportData,
  getDocumentDownloadUrl
}
