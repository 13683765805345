import RatingQuestions from '../RatingQuestions.json'
export default {
  SET_TEAM_RATING_USER (state, data) {
    state.ratingUser = data
  },
  SET_TEAM_RATING_USERS (state, data) {
    state.teamRatingUsers = data
  },
  SET_SELECTED_LANG (state, data) {
    state.selectedLang = data
  },
  SET_OPTIONS_AND_QUESTIONS (state, options) {
    state.traits = options.traits
    state.cognitive = options.cognitive

    state.questionsForData.cognitive = Object.keys(RatingQuestions.cognitive_measures)
    state.questionsForData.traits = Object.keys(RatingQuestions.traits)

    const questionsSv = []
    const questionsEn = []
    for (let i = 0; i < state.questionsForData.cognitive.length; i++) {
      const currentQuestion = state.questionsForData.cognitive[i]
      questionsEn.push(RatingQuestions.cognitive_measures[currentQuestion].EN)
      questionsSv.push(RatingQuestions.cognitive_measures[currentQuestion].SV)
    }
    state.questions.cognitiveSv = questionsSv
    state.questions.cognitiveEn = questionsEn

    const traitsEn = []
    const traitsSv = []
    for (let i = 0; i < Object.keys(RatingQuestions.traits).length; i++) {
      const currentQuestion = Object.keys(RatingQuestions.traits)[i]
      traitsEn.push(RatingQuestions.traits[currentQuestion].EN)
      traitsSv.push(RatingQuestions.traits[currentQuestion].SV)
    }

    state.questions.traitsSv = traitsSv
    state.questions.traitsEn = traitsEn
  }
}
