
export default {
  SET_QUESTIONNAIRE_CONFIG (state, config) {
    state.questionnaire = config
  },
  UPDATE_INDEX (state, index) {
    state.currentQuestionIndex = index
  },
  SET_ANSWERS (state, answers) {
    state.answers[state.currentQuestionIndex] = answers
  },
  SET_USER (state, user) {
    state.user = user
  },
  SET_QUESTIONNAIRE_LANG (state, lang) {
    state.lang = lang
  }
}
