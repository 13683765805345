const Module = () => import('./Module.vue')
const Signup = () => import('./views/Signup.vue')

const moduleRoute = {
  path: '/signup',
  component: Module,
  children: [
    {
      path: '',
      component: Signup,
      beforeEnter: (to, from, next) => {
        const user = localStorage.getItem('user')
        if (user) {
          next({ name: 'Home' })
        } else {
          next()
        }
      }
    }
  ]
}

export default router => {
  router.addRoute(moduleRoute)
}
