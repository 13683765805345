const Module = () => import('./Module.vue')
const Login = () => import('./views/Login.vue')
const Customers = () => import('./views/Customers.vue')
const LowScoreCandidates = () => import('./views/LowScoreCandidates.vue')
const OrganizationTeams = () => import('./views/OrganizationTeams.vue')

const moduleRoute = {
  path: '/admin',
  component: Module,
  children: [
    {
      path: '',
      component: Login,
      beforeEnter: (to, from, next) => {
        const admin = localStorage.getItem('admin')
        if (admin) {
          next({ name: 'Customers' })
        } else {
          next()
        }
      }
    },
    {
      name: 'Customers',
      path: 'customers',
      meta: {
        requiresSuperAdmin: true
      },
      component: Customers
    },
    {
      name: 'LowScoreCandidates',
      path: '/lowscore',
      component: LowScoreCandidates
      // meta: {
      //   requiresAdmin: true
      // }
    },
    {
      name: 'Teams',
      path: 'teams',
      component: OrganizationTeams,
      meta: {
        requiresSuperAdmin: true
      }
    }
  ]
}

export default router => {
  router.addRoute(moduleRoute)
}
