
export default {
  SET_USER (state, user) {
    state.user = user
    localStorage.setItem('user', JSON.stringify(user))
  },
  LOGOUT_USER (state) {
    state.user = {}

    localStorage.removeItem('user')
  },

  // if data is saved in localStorage
  // vuex gets repopulated with stored data
  UPDATE_FROM_LOCAL_STORAGE (state) {
    const user = localStorage.getItem('user')

    if (user) {
      state.user = JSON.parse(user)
    }
  },

  UPDATE_SUBSCRIPTION_STATUS (state, subscriptionStatus) {
    state.user.inTrial = subscriptionStatus.inTrial
    state.user.plan = subscriptionStatus.plan
    state.user.trialDaysRemaning = subscriptionStatus.trialDaysRemaning
    state.user.termRemaningReports = subscriptionStatus.termRemaningReports
    state.user.termRemainingTeams = subscriptionStatus.termRemainingTeams
    state.user.additionalReportsPrice = subscriptionStatus.additionalReportsPrice

    localStorage.setItem('user', JSON.stringify(state.user))
  }
}
