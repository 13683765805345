export default {
  async getQuestionnaire ({ commit }, id) {
    try {
      const questionnaireBattery = await this.$api.getQuestionnaireBattery(id)
      commit('SET_QUESTIONNAIRE_CONFIG', questionnaireBattery)
      return true
    } catch (error) {
      if (error.response.status === 404) {
        return false
      }
    }
  },
  async createUser ({ commit }, email) {
    try {
      const user = await this.$api.createUser(email)
      commit('SET_USER', user)
    } catch (error) {
      console.log(error.response)
      if (error.response.data.statusCode === 400 && error.response.data.message === 'body.email should match format "email"') {
        return 'invalidEmail'
      }
    }
  },
  async submitResults ({ commit, state, getters }, answers) {
    const body = {
      userId: state.user.id,
      type: getters.getCurrentType,
      organisationQuestionnaireBatteryId: state.questionnaire.id,
      answers
    }
    await this.$api.createQuestionnaireResults(body)

    commit('SET_ANSWERS', answers)
  }
}
