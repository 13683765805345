const Module = () => import('./Module.vue')
const Report = () => import('./views/PersonalityReport.vue')

const moduleRoute = {
  path: '/personality',
  component: Module,
  meta: {
    requiresAuth: true
  },
  children: [
    {
      name: 'Personality',
      path: ':id',
      component: Report,
      props: route => ({
        id: route.params.id,
        candidateId: route.query.candidateId || null,
        pdf: String(route.query.pdf).toLowerCase() === 'true',
        anonymized: !route.query.anonymized || String(route.query.anonymized).toLowerCase() === 'true'
      })
    }
  ]
}

export default router => {
  router.addRoute(moduleRoute)
}
