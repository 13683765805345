import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const Module = () => import('../modules/TeamReportAuto/Module.vue')
const Report = () => import('../modules/TeamReportAuto/views/Report.vue')
const TeamScoreboard = () => import('../modules/TeamScoreboard/views/TeamScoreboard.vue')

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/teamreport',
    component: Module,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        name: 'TeamReportAuto',
        path: ':campaignId/reportauto',
        component: Report,
        props: route => ({
          altId: route.params.altId,
          campaignId: route.params.campaignId,
          pdf: String(route.query.pdf).toLowerCase() === 'true',
          organisationId: route.query.organisationId,
          otp: route.query.otp,
          anonymized: !route.query.anonymized || String(route.query.anonymized).toLowerCase() === 'true'
        })
      }
    ]
  },
  {
    path: '/scoreboard',
    component: Module,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: ':campaignId',
        component: TeamScoreboard,
        props: true
      }
    ]
  }
]
const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const user = localStorage.getItem('user')
    if (!user && !to.query.otp) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else if (!to.query.otp) {
      const parsedUser = JSON.parse(user)
      if (parsedUser.subscriptionCancelled && to.name !== 'Checkout') {
        next({
          name: 'Checkout',
          query: { fromTrial: true, inactiveSubscription: true }
        })
      } else {
        next()
      }
    } else {
      next()
    }
  } else {
    next()
  }

  if (to.matched.some(record => record.meta.requiresAdmin)) {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user.role === 'admin') {
      next()
    } else {
      next({
        name: 'Home'
      })
    }
  }

  if (to.matched.some(record => record.meta.requiresSuperAdmin)) {
    const admin = localStorage.getItem('admin')
    if (admin) {
      next()
    } else {
      next({
        path: '/admin'
      })
    }
  }
})

export default router
