const Module = () => import('./Module.vue')
const Create = () => import('./views/Create.vue')
const Overview = () => import('./views/Overview.vue')

const moduleRoute = {
  path: '/requirement-specification',
  component: Module,
  meta: {
    requiresAuth: true
  },
  children: [
    {
      name: 'RequirementSpecifications',
      path: '/',
      component: Overview
    },
    {
      name: 'CreateRequirementSpecification',
      path: 'create',
      component: Create
    }, {
      name: 'EditRequirementSpecification',
      path: ':id',
      props: true,
      component: Create
    }

  ]
}

export default router => {
  router.addRoute(moduleRoute)
}
