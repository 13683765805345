<template>
  <router-view />
</template>

<script>
import jwtDecode from 'jwt-decode'
export default {
  created () {
    // updates store from localStorage
    this.$store.commit('USER/UPDATE_FROM_LOCAL_STORAGE')
    this.$store.commit('ADMIN/UPDATE_FROM_LOCAL_STORAGE_ADMIN')

    if (this.$store.state.USER.user.token) {
      this.$crisp.setTokenId(jwtDecode(this.$store.state.USER.user.token).id)

      this.$crisp.load()

      this.$crisp.user.setEmail(this.$store.state.USER.user.email)
      this.$crisp.user.setNickname(this.$store.state.USER.user.firstName)

      this.$crisp.chat.show()
    }
  }

}
</script>

<style>

</style>
