import * as msal from '@azure/msal-browser'
import Vue from 'vue'

const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_MS_CLIENT_ID,
    authority: process.env.VUE_APP_MS_AUTHORITY,
    redirectUri: process.env.VUE_APP_MS_REDIRECT,
    postLogoutRedirectUri: process.env.VUE_APP_MS_POST_LOGOUT_REDIRECT
  },
  cache: {
    cacheLocation: process.env.VUE_APP_MS_CACHE_LOCATION,
    storeAuthStateInCookie: false
  }
}

const msalInstance = new msal.PublicClientApplication(msalConfig)
Vue.prototype.$msal = msalInstance

export const loginRequest = {
  scopes: ['openid', 'profile']
}

export const tokenRequest = {
  scopes: process.env.VUE_APP_MS_SCOPE.split(',')
}

export default msalInstance
