const Module = () => import('./Module.vue')
const Scoreboard = () => import('./views/Organisation.vue')

const moduleRoute = {
  path: '/organisation',
  component: Module,
  meta: {
    requiresAuth: true,
    requiresAdmin: true
  },
  children: [
    {
      name: 'Organisation',
      path: '',
      component: Scoreboard
    }
  ]
}

export default router => {
  router.addRoute(moduleRoute)
}
