
export default {

  async getCandidates ({ commit }, campaignId) {
    commit('SET_CAMPAIGN_ID', campaignId)

    const candidates = await this.$api.getTestCandidates(campaignId)

    if (candidates.length > 0) {
      commit('SET_CANDIDATES', candidates)
    }
  },

  async getResults ({ state, commit }, { id, otp, campaignId, summary = false }) {
    let data

    try {
      if (summary) {
        data = await this.$api.getSubReport(campaignId, id, 'executive_summary_report')
      } else {
        data = await this.$api.getFullReportData(id, campaignId, otp)
      }
      if (data.message) {
        return data
      }
    } catch (err) {
      console.log(err)
      return err
    }
    commit('SET_MAIN_CANDIDATE', data.summarized)

    if (!state.results[id]) {
      commit('SET_RESULTS', { id, data: data.measureScores })
    }

    commit('SET_BEHAVIORAL_EXPRESSIONS', data.behavioralExpressions)

    return {}
  },

  async getResultsComparison ({ state, commit }, id) {
    if (!state.results[id]) {
      const data = await this.$api.getFullReportData(id, state.campaignId)
      commit('SET_RESULTS', { id, data: data.measureScores })
    }
  },

  async empty ({ commit }) {
    commit('EMPTY')
  }
}
