
export default {
  SET_RESULTS (state, data) {
    state.results = data
  },
  SET_CANDIDATES (state, candidates) {
    state.candidates = candidates
  },
  EMPTY (state) {
    Object.assign(state, {
      candidates: [],
      results: []
    })
  }
}
