
export default {
  SET_ADMIN (state, admin) {
    state.admin = admin
    localStorage.setItem('admin', JSON.stringify(admin))
  },
  LOGOUT_ADMIN (state) {
    state.admin = {}
    localStorage.removeItem('admin')
  },

  UPDATE_FROM_LOCAL_STORAGE_ADMIN (state) {
    const admin = localStorage.getItem('admin')

    if (admin) {
      state.admin = JSON.parse(admin)
    }
  }
}
