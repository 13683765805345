
export default {
  setCurrentCampaign ({ commit, rootState }, campaignId) {
    const { organisationCampaigns, currentPage } = rootState.CAMPAIGNS

    const currentCampaign = organisationCampaigns[currentPage].find(campaign => campaign.id === campaignId)

    commit('SET_CURRENT_CAMPAIGN', currentCampaign)
  }
}
