
export default {
  SET_MEASURE_SCORES (state, data) {
    state.measureScores = data
  },
  SET_TEAM_MEASURE_SCORES (state, data) {
    state.teamMeasureScores = data
  },
  SET_CAMPAIGN_ID (state, campaignId) {
    state.campaignId = campaignId
  },
  SET_TEAM_MEMBERS (state, teamMembers) {
    state.teamMembers = teamMembers
  },
  SET_TEAM_MEMBERS_SUMMARIZED (state, teamMembersSummarized) {
    state.teamMembersSummarized = teamMembersSummarized
  },
  SET_LOADED_DATA (state, val) {
    state.loadedData = val
  },
  EMPTY (state) {
    Object.assign(state, {
      campaignId: null,
      measureScores: {},
      teamMeasureScores: {},
      teamMembers: [],
      teamMembersSummarized: {},
      loadedData: false
    })
  }
}
