import axios from 'axios'
import store from '@/store'
// import router from '@/router'
import msalInstance, { tokenRequest } from '@/plugins/Azure'

function getTokenRedirect () {
  const tokenRequestTmp = tokenRequest
  tokenRequestTmp.account = msalInstance.getAccountByUsername(store.state.ADMIN.admin.account.username)
  return msalInstance.acquireTokenSilent(tokenRequestTmp)
    .catch(error => {
      console.error(error)
      console.warn('silent token acquisition fails. acquiring token using popup')
      if (error) {
        return msalInstance.acquireTokenRedirect(tokenRequestTmp)
      } else {
        console.error(error)
      }
    })
}

const httpAdmin = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

httpAdmin.interceptors.request.use(async config => {
  const { accessToken } = await getTokenRedirect()
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`
  }
  return config
}, error => {
  return Promise.reject(error)
})

// httpAdmin.interceptors.response.use(response => response, error => {
//   if (error.response.status === 401 && !(error.response.data.message === 'Invalid credentials' || error.response.data.message === 'Invalid code')) {
//     store.commit('USER/LOGOUT_USER')
//     router.push('/login')
//     router.go()
//   }
//   return Promise.reject(error)
// })

export default httpAdmin
