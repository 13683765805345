const Module = () => import('./Module.vue')
const Checkout = () => import('./views/Checkout.vue')

const moduleRoute = {
  path: '/checkout',
  component: Module,
  meta: {
    requiresAuth: true
  },
  children: [
    {
      name: 'Checkout',
      path: '',
      component: Checkout,
      props: route => ({
        firstCheckout:
          String(route.query.fromTrial).toLowerCase() === 'true',
        inactiveSubscription:
          String(route.query.inactiveSubscription).toLowerCase() === 'true'
      })
    }
  ]
}

export default router => {
  router.addRoute(moduleRoute)
}
