export default {
  isLoggedIn: state => {
    return state.user.token
  },
  getAvatar: state => {
    return state.user.firstName[0].toUpperCase() + state.user.lastName[0].toUpperCase()
  },
  getSubscription: state => {
    return { plan: state.user.plan, inTrial: state.user.inTrial, trialDaysRemaning: state.user.trialDaysRemaning, termRemaningReports: state.user.termRemaningReports, termRemainingTeams: state.user.termRemainingTeams, additionalReportsPrice: state.user.additionalReportsPrice }
  },
  getOrgAdminDetails: state => {
    const admin = { admin: state.user.admin, organizationsName: state.user.organizationsName, organizationId: state.user.organizationId }
    return admin
  }

}
