
export default {
  async getResultsComparison ({ state, commit }, campaignId) {
    const data = await this.$api.getTeamResults(campaignId)

    commit('SET_RESULTS', data.measureScores)
    commit('SET_CANDIDATES', data.teamMembers)
  },

  async empty ({ commit }) {
    commit('EMPTY')
  }
}
