export default () => ({
  issues: {},
  lowScoreComments: {},
  candidates: {},
  teamReportLink: null,
  summaryData: {
    state_median: 0,
    capacity_avg: 0,
    awareness_avg: 0,
    variation_avg: 0,
    strength_max: '',
    weakness_min: ''
  },
  filterOptions: {
    team_roles: [],
    divisions: [],
    departments: [],
    companies: [],
    positions: []
  },
  currentPage: 0,
  pages: null,
  sorting: {
    variable: 'user_id',
    invert: false
  },
  filters: {
    endurance: null,
    variation: null,
    capacity_level: null,
    favoritesOnly: false,
    state: {
      RED: false,
      YELLOW: false,
      GREEN: false
    },
    awareness: {
      OVER: false,
      UNDER: false,
      CLOSE: false,
      DIVERGENT: false
    },
    gender: {
      Male: false,
      Female: false,
      Other: false
    },
    strength: {
      working_memory: false,
      simple_attention: false,
      strategic_thinking: false,
      short_term_memory: false,
      impulse_control: false,
      creativity_cognitive_flexibility: false,
      conceptualization: false,
      attention_control: false
    },
    requirementSpecificationId: 'NO_REQ_SPEC'
  }
})
