import { Charts } from '@/content/reportContent.json'
import { parseMainMeasures, parseEasyHardMeasures } from '@/utils/chartUtils.js'
export default {

  getTeamMembers (state) {
    return state.teamMembers
  },

  getTeamMembersSummarized (state) {
    return state.teamMembersSummarized
  },

  getMeasureScores (state) {
    return state.measureScores
  },

  getTeamMeasureScores (state) {
    return state.teamMeasureScores
  },

  getLoadedData (state) {
    return state.loadedData
  },

  getMainMeasures (state) {
    const results = state.results[state.mainCandidate.user_id]

    return parseMainMeasures(results)
  },
  getQualityMeasures: (state) => {
    const results = state.results[state.mainCandidate.user_id]
    const qualityMeasures = []
    results.forEach(result => {
      const qualityMeasure = Charts.QualityMeasures[result.measure]
      if (qualityMeasure) {
        qualityMeasures.push({ stanine: result.stanine, label: qualityMeasure.label })
      }
    })

    return qualityMeasures
  },

  getEasyHardMeasures (state) {
    const results = state.results[state.mainCandidate.user_id]

    if (!results || results === undefined) {
      return {}
    }
    return parseEasyHardMeasures(results)
  }

  // 1. Bryt ut logiken i den här funktionen till filen som heter Chart Utils
  // 1. Funktionen ska ta in resultatet från API't när vi tar in stanine-datan
  // 2. Kalla funktionen ParseEasyHardMeasures
  // 3. Ersätt ParseEasyHardMeasures-funktionen som körs i den här filen
  // 4. Kör i SubReport också
}
