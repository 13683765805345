export default () => ({
  ratingUser: null,
  teamRatingUsers: null,
  selectedLang: null,
  traits: null,
  cognitive: null,

  questions: {
    cognitiveSv: null,
    traitsSv: null,
    cognitiveEn: null,
    traitsEn: null
  },
  questionsForData: {
    cognitive: null,
    traits: null
  }

})
