const Module = () => import('./Module.vue')
const TeamAnalytics = () => import('./views/TeamAnalytics.vue')
const TeamScoreboard = () => import('@/modules/TeamScoreboard/views/TeamScoreboard.vue')

const moduleRoute = {
  path: '/teams',
  component: Module,
  meta: {
    requiresAuth: true
  },
  children: [
    {
      name: 'TeamAnalytics',
      path: '/teams/:campaignId',
      props: true,
      component: TeamAnalytics

    },
    {
      name: 'TeamScoreboard',
      path: '/teams/:campaignId/scoreboard',
      props: true,
      component: TeamScoreboard
    }
  ]
}

export default router => {
  router.addRoute(moduleRoute)
}
