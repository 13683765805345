import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { Crisp } from 'crisp-sdk-web'

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

http.interceptors.request.use(config => {
  if (store.state.USER.user.token) {
    config.headers.Authorization = `Bearer ${store.state.USER.user.token}`
  }
  return config
}, error => {
  return Promise.reject(error)
})

http.interceptors.response.use(response => response, error => {
  if (error.response.status === 401 && !(error.response.data.message === 'Invalid credentials' || error.response.data.message === 'Invalid code')) {
    store.commit('USER/LOGOUT_USER')

    Crisp.session.reset()
    Crisp.chat.hide()

    router.push('/login')
    router.go()
  }
  return Promise.reject(error)
})

export default http
