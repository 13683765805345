
export default {
  getQuestionnaireList: state => state.questionnaire.questionnairesList,
  getCurrentType: state => state.questionnaire.questionnairesList[state.currentQuestionIndex],
  validInvite: state => {
    if (state.questionnaire.id) {
      return true
    }
    return false
  }
}
