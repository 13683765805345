const Module = () => import('./Module.vue')
const Overview = () => import('./views/Overview.vue')
const Distribute = () => import('./views/Distribute.vue')
const ComingSoon = () => import('./views/ComingSoon.vue')

const moduleRoute = {
  path: '/teams',
  component: Module,
  meta: {
    requiresAuth: true
  },
  children: [
    {
      name: 'ComingSoon',
      path: 'comingSoon',
      component: ComingSoon
    },
    {
      name: 'TeamBrain',
      path: ':page',
      component: Overview
    },
    {
      name: 'Distribute',
      path: ':page/:campaignId/distribute/:batteryId',
      component: Distribute,
      props: route => ({
        isQuestionnaire: (route.query.qst === 'true'),
        testBatteryId: route.params.batteryId,
        campaignId: route.params.campaignId
      })
    }
  ]
}

export default router => {
  router.addRoute(moduleRoute)
}
