const Module = () => import('./Module.vue')
const verify = () => import('./views/verify.vue')

const moduleRoute = {
  path: '/verify',
  component: Module,
  children: [
    {
      path: '',
      component: verify
    }
  ]
}

export default router => {
  router.addRoute(moduleRoute)
}
