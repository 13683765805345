export default {
  async msLoginAdmin ({ commit }, creds) {
    if (creds !== null || creds !== undefined) {
      commit('SET_ADMIN', creds)
      return true
    } else {
      return false
    }
  }

}
