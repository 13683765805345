
export default {
  getTeams: state => {
    const currentPageTeams = state.teams[state.currentPage] || []
    return currentPageTeams.map(el => {
      const createdDate = new Date(el.createdAt)
      const today = new Date()
      const fourHours = 1000 * 60 * 60 * 4
      // add new tag to test if less than four hours old
      if (today - createdDate < fourHours) {
        el.new = true
        return el
      }
      return el
    }).sort((a, b) => new Date(a.createdAt) < new Date(b.createdAt))
  },
  getPages: state => {
    return [state.currentPage, state.pages]
  },
  getTeam: state => id => {
    if (state.teams[state.currentPage]) return state.teams[state.currentPage].find(team => team.id === id)
    else return {}
  }
  // getCurrentCampaign (state) {
  //   return state.currentCampaign
  // }
}
