const Module = () => import('./Module.vue')
const CreateCampaign = () => import('./views/CreateCampaign.vue')

const moduleRoute = {
  path: '/create-campaign',
  component: Module,
  children: [
    {
      path: '/',
      name: 'CreateCampaign',
      component: CreateCampaign
    }
  ]
}

export default router => {
  router.addRoute(moduleRoute)
}
