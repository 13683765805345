const Module = () => import('./Module.vue')
const TeamScoreboard = () => import('./views/TeamScoreboard.vue')

const moduleRoute = {
  path: '/scoreboard',
  component: Module,
  meta: {
    requiresAuth: true
  },
  children: [
    {
      path: ':campaignId',
      component: TeamScoreboard,
      props: true
    }
  ]
}

export default router => {
  router.addRoute(moduleRoute)
}
