const Module = () => import('./Module.vue')
const Analytics = () => import('./views/Analytics.vue')
const SelectCampaign = () => import('./views/SelectCampaign.vue')
const Scoreboard = () => import('@/modules/Scoreboard/views/Scoreboard.vue')
// const QuestionnaireReport = () => import('@/modules/QuestionnaireReport/index.vue')

const moduleRoute = {
  path: '/analytics',
  component: Module,
  meta: {
    requiresAuth: true
  },
  children: [
    {
      name: 'SelectCampaign',
      path: ':page',
      component: SelectCampaign
    },
    {
      name: 'Analytics',
      path: ':page/:campaignId',
      props: true,
      component: Analytics

    },
    {
      name: 'Scoreboard',
      path: ':page/:campaignId/scoreboard',
      props: true,
      component: Scoreboard
    }
  ]
}

export default router => {
  router.addRoute(moduleRoute)
}
