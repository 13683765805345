const Module = () => import('./Module.vue')
const Dashboard = () => import('./views/Dashboard.vue')

const moduleRoute = {
  path: '/team-home',
  component: Module,
  meta: {
    requiresAuth: true
  },
  children: [
    {
      name: 'TeamHome',
      path: '',
      component: Dashboard
    }
  ]
}

export default router => {
  router.addRoute(moduleRoute)
}
