
export default {
  SET_RESULTS (state, { data, id }) {
    state.results[id] = data
  },
  SET_CANDIDATES (state, candidates) {
    state.candidates = candidates
  },
  SET_MAIN_CANDIDATE (state, candidate) {
    state.mainCandidate = candidate
  },
  SET_CAMPAIGN_ID (state, campaignId) {
    state.campaignId = campaignId
  },
  EMPTY (state) {
    Object.assign(state, {
      candidates: [],
      mainCandidate: null,
      results: {}
    })
  },
  SET_BEHAVIORAL_EXPRESSIONS (state, data) {
    state.behavioralExpressions = data
  }
}
