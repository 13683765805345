const Module = () => import('./Module.vue')
const CreateTeam = () => import('./views/CreateTeam.vue')

const moduleRoute = {
  path: '/',
  component: Module,
  children: [
    {
      path: '/teams/create',
      name: 'CreateTeam',
      component: CreateTeam
    }
  ]
}

export default router => {
  router.addRoute(moduleRoute)
}
