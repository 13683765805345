
export default {
  getCandidates: state => {
    return state.candidates[state.currentPage] || []
  },
  getCandidatesTestIssues: state => {
    return state.issues || []
  },
  getLowScoreComments: state => {
    return state.lowScoreComments || []
  },
  getPages: state => {
    return [state.currentPage, state.pages]
  },
  getFilterOptions: state => {
    return state.filterOptions
  }
}
