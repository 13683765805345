import Vue from 'vue'

export default {
  SET_TEAMS (state, data) {
    Vue.set(state.teams, state.currentPage, data)
  },
  SET_PAGES (state, pages) {
    state.pages = pages
  },
  SET_CURRENT_PAGE (state, page) {
    state.currentPage = page
  }
  // SET_CURRENT_CAMPAIGN (state, campaign) {
  //   state.currentCampaign = campaign
  // }
}
