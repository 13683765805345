import Vue from 'vue'
import Vuex from 'vuex'

import CONFIGS from './modules/configs'
import CAMPAIGNS from './modules/campaigns'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {},
  getters: {},
  actions: {},
  mutations: {},
  modules: {
    CONFIGS,
    CAMPAIGNS
  }
})

export default store
