import { Charts } from '@/content/reportContent.json'

const normColor = 'rgba(162, 209, 248, 1)'
const candidateColor = 'rgba(0, 0, 0, 1)'
const footballEliteColor = '#054A29'
const footballSemiEliteColor = '#62C370'
const xaviColor = '#14213D'
const iniestaColor = '#FCA311'

export const spiderOptions = {
  responsive: true,
  scales: {
    r: {
      max: 9,
      beginAtZero: true,
      ticks: {
        z: 1,
        font: {
          size: 10
        }
      },
      pointLabels: {
        font: {
          size: 16
        },
        color: 'black',
        callback: label => {
          if (label.includes('&')) {
            const split = label.split('&')
            split[0] = split[0] + ' &'
            return split
          }
          return label
        }
      }
    }
  },
  plugins: {
    legend: {
      align: 'center',
      position: 'bottom',
      labels: {
        font: {
          size: 16
        }
      }
    }
  }
}

export function parseEasyHardMeasures (results) {
  if (!Array.isArray(results) || results === undefined) {
    return {}
  }
  const easyHardMeasures = {}
  results.forEach(result => {
    const easyHardMeasure = Charts.EasyHardMeasures[result.measure]
    if (easyHardMeasure) {
      if (easyHardMeasures[easyHardMeasure.key]) {
        if (easyHardMeasure.easy) {
          easyHardMeasures[easyHardMeasure.key].easy = result.stanine
        } else {
          easyHardMeasures[easyHardMeasure.key].hard = result.stanine
        }
      } else {
        if (easyHardMeasure.easy) {
          easyHardMeasures[easyHardMeasure.key] = { easy: result.stanine, label: easyHardMeasure.label }
        } else {
          easyHardMeasures[easyHardMeasure.key] = { hard: result.stanine, label: easyHardMeasure.label }
        }
      }
    }
  })

  return easyHardMeasures
}

export function parseMainMeasures (results) {
  const mainMeasures = []

  results.forEach(result => {
    const mainMeasure = Charts.MainMeasures[result.measure]
    if (mainMeasure) {
      mainMeasures[mainMeasure.order] = {
        stanine: result.stanine,
        label: mainMeasure.label,
        comparativePerformanceLevel: result.comparative_performance_level || null,
        outcomeMax: result.outcome_max || null,
        tirednessLevel: result.tiredness_level || null
      }
    }
  })

  // Fill missing measures with zero
  for (const key in Charts.MainMeasures) {
    const index = Charts.MainMeasures[key].order
    if (!mainMeasures[index]) {
      mainMeasures[index] = {
        stanine: 0,
        label: Charts.MainMeasures[key].label,
        maxEstimate: 0,
        outcomeMax: 0,
        tirednessLevel: 0,
        placeholder: true
      }
    }
  }

  return mainMeasures
}

export const candidateStyling = {
  fill: false,
  backgroundColor: candidateColor,
  borderColor: candidateColor,
  pointBackgroundColor: candidateColor,
  pointBorderColor: candidateColor,
  pointHoverBackgroundColor: candidateColor,
  pointHoverBorderColor: candidateColor
}
export const normStyling = {
  fill: false,
  backgroundColor: normColor,
  borderColor: normColor,
  pointBackgroundColor: normColor,
  pointBorderColor: normColor,
  pointHoverBackgroundColor: normColor,
  pointHoverBorderColor: normColor
}

export const footballStyling = {
  elite: {
    fill: false,
    backgroundColor: footballEliteColor,
    borderColor: footballEliteColor,
    pointBackgroundColor: footballEliteColor,
    pointBorderColor: footballEliteColor,
    pointHoverBackgroundColor: footballEliteColor,
    pointHoverBorderColor: footballEliteColor
  },
  semiElite: {
    fill: false,
    backgroundColor: footballSemiEliteColor,
    borderColor: footballSemiEliteColor,
    pointBackgroundColor: footballSemiEliteColor,
    pointBorderColor: footballSemiEliteColor,
    pointHoverBackgroundColor: footballSemiEliteColor,
    pointHoverBorderColor: footballSemiEliteColor
  },
  xavi: {
    fill: false,
    backgroundColor: xaviColor,
    borderColor: xaviColor,
    pointBackgroundColor: xaviColor,
    pointBorderColor: xaviColor,
    pointHoverBackgroundColor: xaviColor,
    pointHoverBorderColor: xaviColor
  },
  iniesta: {
    fill: false,
    backgroundColor: iniestaColor,
    borderColor: iniestaColor,
    pointBackgroundColor: iniestaColor,
    pointBorderColor: iniestaColor,
    pointHoverBackgroundColor: iniestaColor,
    pointHoverBorderColor: iniestaColor
  }
}
