const Module = () => import('./Module.vue')
const Profile = () => import('./views/Profile.vue')

const moduleRoute = {
  path: '/profile',
  component: Module,
  meta: {
    requiresAuth: true
  },
  children: [
    {
      name: 'Profile',
      path: '',
      component: Profile
    }
  ]
}

export default router => {
  router.addRoute(moduleRoute)
}
