const Module = () => import('./Module.vue')
const StartPage = () => import('./views/StartPage.vue')
const Questions = () => import('./views/Questionnaire.vue')
const DonePage = () => import('./views/Donepage.vue')
const Page404 = () => import('./views/404page.vue')
const resultsView = () => import('./views/Results.vue')

const moduleRoute = {
  path: '/TeamRating/:campaignId',
  component: Module,
  children: [
    {
      path: '',
      component: StartPage,
      props: true
    },
    {
      path: 'Question',
      component: Questions,
      props: true
    },
    {
      path: 'Finished',
      component: DonePage
    },
    {
      path: '404',
      component: Page404
    },
    {
      name: 'Results',
      path: 'Results',
      component: resultsView,
      metadata: {
        requiresAuth: true
      },
      props: true
    }
  ]
}

export default router => {
  router.addRoute(moduleRoute)
}
