import Vue from 'vue'
// import api from '@/api'

const Chargebee = window.Chargebee

Chargebee.init({
  site: process.env.VUE_APP_CHARGEBEE_SITE_NAME
})

const chargebeeInstance = Chargebee.getInstance()

Vue.prototype.$chargebee = chargebeeInstance
