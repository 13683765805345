import { parseMainMeasures } from '@/utils/chartUtils.js'
export default {

  getCandidates (state) {
    return state.candidates
  },

  getMainMeasures (state) {
    const results = state.results

    return parseMainMeasures(results)
  }
}
